body {
  margin: 0;
}

.wrapper {
  background-color: white;
  margin-left: 10%;
  width: 80%;
}


h1 {
  align-self: center;
  /* padding-top: 5px; */
}

.form-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.form-container div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 5px;
  border: solid 1px #0000004d ;
  justify-content: space-evenly;
  flex-grow: 1;
}

.form-container label {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-container input {
  line-height: 1.7;
  border: solid 1px #0000004d ;
  border-radius: 5px;
  transition: border-width 0.1s linear;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  
}

.form-container input:hover {
  border-width: 2px;
}

.form-container p {
  color: red;
}


.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c:no-repeat radial-gradient(farthest-side,#007A35 92%,#0000);
  background: 
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}
@keyframes l7 {to{transform: rotate(.5turn)}}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 25%;
  height: 25%;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
*, ::after, ::before {
  box-sizing: border-box;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

blockquote, body, dd, dl, figure, h1, h2, h3, h4, p {
  margin-block-end: 0;
}

ol[role=list], ul[role=list] {
  list-style: none;
}

body {
  min-height: 100vh;
  line-height: 1.5;
}

button, h1, h2, h3, h4, input, label {
  line-height: 1.1;
}

h1, h2, h3, h4 {
  text-wrap: balance;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: currentColor;
}

img, picture {
  max-width: 100%;
  display: block;
}

button, input, select, textarea {
  font: inherit;
}

textarea:not([rows]) {
  min-height: 10em;
}

:target {
  scroll-margin-block: 5ex;
}

body {
  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.wrapper {
  margin-bottom: 80px;
}

input, select {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3019607843);
  border-radius: 4px;
  height: 40px;
  border-style: inset;
  padding: 0 8px;
  margin: 0 !important;
  font-size: 16px;
  margin-bottom: 24px !important;
}
input:hover, select:hover {
  border-width: 1px !important;
  outline: 2px solid #79d5a1;
  transition: all 160ms ease-in-out;
}

label {
  text-align: left;
  margin: 0 0 4px !important;
  font-size: 10px !important;
}

p.error {
  font-weight: 700 !important;
  margin: 8px 0 !important;
}

.App {
  text-align: left;
}
.App h1 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}
.App h3 {
  font-size: 20px;
  margin-bottom: 24px;
}

.form-container > .leftcol {
  flex: 2 1 auto !important;
  border: none;
  padding: 0 !important;
}

.form-container > .rightcol {
  padding: 0 40px;
  justify-content: flex-start;
  margin-bottom: 16px;
}

button {
  border: none;
  outline: none;
  background-color: #007A35;
  border-radius: 8px;
  color: #fff;
  padding: 8px 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
button:hover {
  background-color: #004f22;
  transition: all 160ms ease-in-out;
}

.ccexp {
  border: none !important;
  flex-direction: row !important;
  width: 100% !important;
}
.ccexp div {
  border: none !important;
}
.ccexp div + div {
  margin: 0 0 0 8px;
}
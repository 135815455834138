.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header{
  position: relative;
}

.contact {
  height: 100px;
  font-size: 1em;
  background-color: #007A35;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.contact-container {
  display: flex;
  flex-wrap: nowrap;
  float: right;
  margin-right: 150px;
  margin-top: 25px;
  align-items: center;
}

.contact span {
  background-color: #FFD712;
  color: black;
  width: 210px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact span:hover {
  color: grey;
}

.contact a {
  font-size: 18px; 
}

.topnav {
  display: flex;
  background-color: black;
  height: 125px;
  align-items: center;
}


.topnav img {
  display: block;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  font-size: 18px;
  height: 25px;
}

.logo {
  flex: 1;
  padding-left: 150px;
}

.links {
  display: inline-block;
  padding-right: 150px;
}

.links span {
  font-weight: 300;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 18px;    
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-weight: 300;
  margin: 0;
}

.dropdown span {
  color: white;
  padding-right:  16px;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 300;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: 50px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  display: block;
  text-align: right;
}

.topnav a:hover, .dropdown:hover, .dropdown span:hover, .dropbtn {
  color: #007A35;
}


.dropdown-content a:hover {
  color: grey;
}

.dropdown:hover .dropdown-content {
  display: block;
}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}

.arrow {
  border: solid #0AB24F;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-right: 2px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 1350px) {
  .topnav a:not(:last-child), .dropdown, .dropdown span {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}
/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-size: 25px;
  color: black;
  padding: 13px;
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 1350px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
   
  }

  .topnav.responsive .dropdown span{
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
  }
   
  .topnav.responsive .dropdown .dropdown span {
    display: block;
    width: 100%;
    
  }
}